<template>
    <!-- res -->
    <section class="container contenedor-owl mb-5">
        <div class="row">
            <div class="col-lg-12 mb-0 mb-lg-4">
                <div class="row align-items-center">
                    <div class="col-lg-6 mb-lg-0 mb-4">
                        <div class="titulo-destacado d-block">
                            <div class="titulos d-block mb-3">
                                <h2 class="font-weight-bold text-uppercase color-principal">Viaja con Nosotros</h2>
                                <h6 class="text-muted">Descubre esta maravillosa de ciudad viajando con nosotros al destino que gustes. Terminal de Santa Marta</h6>
                            </div>
                            <div class="decoracion">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg mb-lg-0 mb-4">
                        <h5 class="color-principal">Desde nuestras terminales puedes encontrar ofertas de viaje seguro hacia cualquiera destino nacional. Conoce todo acerca de los principales destinos turísticos que puedes visitar.</h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 my-4">
                <Lugares :ciudades="destinos"></Lugares>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
import Lugares from '@/components/home/Lugares.vue'
export default {
    created() {
        this.ApicDestinos();
    },
    name: '',
    data: function() {
        return {
            destinos: null,
            cities: [{
                img: 'foto-1.jpg',
                url: '#',
                texto: 'De Santa Marta a Bogota'
            }, ]
        }
    },
    methods: {
        async ApicDestinos() {
            await axios({
                    method: 'GET',
                    url: `https://noticias.terminaldesantamarta.com/destinos`,
                    responseType: 'json',
                })
                .then(res => {
                    console.log("res", res.data);
                    this.destinos = res.data;
                }).catch(err => console.err(err));
        }
    },
    components: {
        Lugares
    }
}
</script>