<template>
    <div class="d-block">
        <div class="grid-lugares">
            <div class="grid" v-for="(item, index) of ciudades" v-bind:key="index">
                <div class="card card-ciudad">
                    <a target="_blank" :href="transformUrl(item.url)" class="btn tema-principal">
                        <i class="icon-price-tag mr-2"></i> Comprar tiquete
                    </a>
                    <img :src="BASE_URL+item.imagen.url" class="card-img-top" alt="terminal-de-santamarta">
                    <div class="card-body">
                        <p class="card-text h4">{{ item.nombre }}</p>
                        <ul>
                            <li><strong>Aprox:</strong> {{ item.tiempo }} Kilometros</li>
                            <li><strong>Distancia:</strong> {{ item.distancia }} Kilometros</li>
                            <li><i class="icon-clock mr-2"></i> {{ item.horarios }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="grid">
                <router-link to="/destinos" class="card card-ciudad" @click="scrollToTop">
                    <img src="@/assets/img/foto-5.jpg" class="card-img-top" alt="terminal-de-santamarta">
                    <div class="card-body cortina">
                        <h4>Ver todos nuestros destinos de viaje</h4>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: 'Lugares',
    mounted() {
        this.getTime();
    },
    data(){
        return {
            fechaStart: '',
        }
    },
    props: {
        ciudades: {
            type: Array
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        transformUrl(url){
            return url.replace('dateCustom', this.fechaStart);;
        },
        getTime(){
            const hoy = new Date();
            const dd = hoy.getDate();
            const mm = hoy.getMonth();
            const aa = hoy.getFullYear();
            this.fechaStart = `${aa}-${mm}-${dd}`;
        }
    },
    computed: {
        ...mapState(['BASE_URL'])
    }
}
</script>