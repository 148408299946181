<template>
    <!-- res -->
    <header class="header-home">
        <div id="carouselExampleIndicators" class="carousel slide carousel-inicio mb-4" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="@/assets/img/foto-6.jpeg" class="d-block w-100" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="@/assets/img/foto-2.jpg" class="d-block w-100" alt="...">
                </div>
                <div class="carousel-item">
                    <img src="@/assets/img/foto-3.jpg" class="d-block w-100" alt="...">
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <div class="buscadorTiquete">
            <div class="container p-0">
                <TiquetesBus></TiquetesBus>
                <!-- <div class="widget clearfix" data-widgetid="TERMINALDETRANSPORTESDESANTAMARTA" style="
    position: relative;
    width: 80%;
    margin:auto;"></div> -->
            </div>
        </div>
    </header>
</template>
<script>
import TiquetesBus from '@/components/home/TiquetesBus.vue';

export default {
    components: {
        TiquetesBus
    },
    name: 'HeaderSlider',
    data: function () {
        return {

        }
    }
}
</script>

<style lang="css">
.widget {
    position: relative;
    width: 100%;
    margin: 0 auto;
}
.header-home {
    position: relative;
    display: flex;
    justify-content: center;
}

.header-home .carousel {
    width: 100%;
    background-color: #F8EBC0;
}
.header-home .carousel-inner {
    overflow: hidden;
}

.header-home .carousel .carousel-item {
    position: relative;
    height: 350px;
}

.header-home .carousel .carousel-item img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
}

.header-home .buscadorTiquete {
    border: 8px solid rgba(255, 109, 0, .5);
    position: absolute;
    top: 1rem;
    z-index: 5;
    /*overflow: hidden;*/
    border-radius: 12px;
    box-shadow: 0 0 22px rgba(0, 0, 0, .2);
    background-color: #ff6d00;
}

.header-home .container {
    background-color: #fff;
}

.filtro-destino {
    border-top: 1px solid rgba(255, 109, 0, .5);
}

.filtro-destino>*+* {
    border-left: 1px solid rgba(255, 109, 0, .5);
}

.filtro-destino input,
.filtro-destino select,
.filtro-destino input {
    border: 0;
    border-radius: 0;
    padding: 0 40px;
}

.filtro-destino .form-control:focus {
    box-shadow: none !important;
}

@media (max-width: 768px) {
    .header-home .buscadorTiquete {
        top: 100%;
        transform: translateY(-50%);
    }

    .header-home {
        margin-bottom: 10rem;
    }

    .header-home .carousel .carousel-item  {
        height: 40vh;
    }
}

@media (max-width: 768px) {

    .filtro-destino>*+* {
        border-left: 0;
        border-top: 1px solid rgba(255, 109, 0, .5);
    }
}
</style>
