<template>
    <!-- res -->
    <section class="container contenedor-fomr">
        <div class="row">
            <form class="col-12 fomr_tiquete p-4 sombra-1 tema-principal">
                <div class="form-row align-items-end form-tiquet">
                    <div class="col-lg col-12 mb-lg-0 mb-3">
                        <label for="destino">Origen</label>
                        <input type="text" id="destino" value="Santa Marta" disabled class="form-control" placeholder="Destino">
                    </div>
                    <div class="col-lg col-12 mb-lg-0 mb-3">
                        <label for="origen">Destino</label>
                        <input type="text" id="searchDestino" class="form-control" placeholder="Destino" v-model="buscarOrigen">
                        <div class="filtro shadow-lg" v-if="destinosFilter.length > 0">
                            <ul>
                                <li v-for="item of destinosFilter" :key="item.id" v-on:click="select(item)">
                                    {{ item.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg col-12 mb-lg-0 mb-3">
                        <label for="inputState">Fecha salida</label>
                        <input :min="fechaMin" type="date" class="form-control" v-model="fechaStart">
                    </div>
                    <div class="col-lg col-12 mb-lg-0 mb-3">
                        <label for="inputState">Regreso (Opcional)</label>
                        <input :min="fechaMin" type="date" class="form-control">
                    </div>
                    <div class="col-lg-auto col-12 mt-4 mt-lg-0">
                        <button type="button" class="btn fondo-secundario-s w-100" v-on:click="action">
                            <i class="icon-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>
<script>
import axios from 'axios';
export default {
    name: 'TiquetesBus',
    created() {
        this.ApicDestinos();
    },
    mounted() {
        this.searchDestino();
    },
    data(){
        return {
            destinos: [],
            destinosFilter: [],
            destino: null,
            buscarOrigen: '',
            fechaStart: '',
            fechaMin: ''
        }
    },
    methods: {
        async ApicDestinos() {
            const hoy = new Date();
            const dd = hoy.getDate();
            const mm = hoy.getMonth();
            const aa = hoy.getFullYear();
            this.fechaStart = `${aa}-${mm}-${dd}`;
            this.fechaMin = `${aa}-${mm}-${dd}`;
            await axios({
                    method: 'GET',
                    url: `https://noticias.terminaldesantamarta.com/destinos`,
                    responseType: 'json',
                })
                .then(resp => {
                    const destinos = resp.data.map(item => {
                        const destino = item.nombre.split('-');
                        return {
                            distancia: item.distancia,
                            horario: item.horarios,
                            origen: destino[0],
                            destino: destino[1],
                            url: item.url
                        }
                    })
                    console.log("destinos", destinos);
                    this.destinos = destinos;
                }).catch(err => console.err(err));
        },
        searchDestino(){
            const input = document.querySelector('#searchDestino');
            input.addEventListener('input', (e) => {
                const value = e.target.value;
                this.destinosFilter = [];
                if(value.length > 0){
                    this.destinos.forEach(dest => {
                        if (dest.destino.toUpperCase().includes(value.toUpperCase())) {
                            this.destinosFilter.push({
                                name: dest.destino,
                                url: dest.url
                            })
                        }
                    });
                } else {
                    this.destinosFilter = [];
                }
            })
        },
        select(item){
            this.buscarOrigen = item.name;
            this.destino = item;
            this.destinosFilter = [];
        },
        action(){
            const url = this.destino.url.replace('dateCustom', this.fechaStart);
            window.open(url, '_blank');
        }
    },
}
</script>
<style type="text/css">
.form-tiquet .filtro {
    position: absolute;
    font-weight: 100 !important;
    background-color: #fff;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 12px;
    color: #000;
    z-index: 1;
    padding: 10px;
}

.form-tiquet .filtro ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.form-tiquet .filtro ul li {
    display: block;
    padding: 5px 10px;
    cursor: pointer;
}
.form-tiquet .filtro ul li:hover {
    background-color: #FFECD9;
}

.form-tiquet .filtro ul li:last-child {
    border: 0;
}
</style>