<!-- res -->
<template>
    <section class="container mb-5">
        <div class="row">
            <div class="col-12">
                <div class="grid_iconos">
                    <template  v-for="item of rutas" :key="item.id">
                        <router-link v-if="item.type === 'self'" class="item" :to="item.ruta" @click="scrollToTop">
                            <div class="cortina fondo-opaco-4"></div>
                            <div class="icono">
                                <i :class="['display-4 d-block mb-2', item.icono]"></i>
                                <p class="h5">{{ item.desc }}</p>
                            </div>
                        </router-link>
                        <a v-else target="_blank" :href="item.ruta" class="item">
                            <div class="cortina fondo-opaco-4"></div>
                            <div class="icono">
                                <i :class="['display-4 d-block mb-2', item.icono]"></i>
                                <p class="h5">{{ item.desc }}</p>
                            </div>
                        </a>
                    </template>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'SetIconos',
    data: function() {
        return {
            rutas: [
                {
                    id: 0,
                    type: 'self',
                    ruta: '/entidad/AtencionCiudadano',
                    icono: 'icon-user-check',
                    desc: 'Atención al ciudadano'
                },
                {
                    id: 1,
                    type: 'self',
                    ruta: '/entidad/peticiones-quejas',
                    icono: 'icofont-attachment',
                    desc: 'Peticiones y quejas'
                },
                {
                    id: 2,
                    type: 'self',
                    ruta: '/entidad/informacion-corporativa',
                    icono: 'icofont-search-document',
                    desc: 'Información corporativa'
                },
                {
                    id: 3,
                    type: 'target',
                    ruta: 'https://www.contratos.gov.co/consultas/resultadoListadoProcesos.jsp',
                    icono: 'icon-home',
                    desc: 'Procesos contractuales'
                },
                {
                    id: 4,
                    type: 'self',
                    ruta: '/entidad/tranparenciaAcceso',
                    icono: 'icon-home',
                    desc: 'Transparencia y accesos'
                },
                {
                    id: 5,
                    type: 'self',
                    ruta: '/entidad/planeacion-y-gestion',
                    icono: 'icofont-chart-growth',
                    desc: 'Planeación y gestión'
                },
                {
                    id: 6,
                    type: 'self',
                    ruta: '/participacion-ciudadana',
                    icono: 'icon-stats-bars',
                    desc: 'Encuestas'
                }
            ]
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
}
</script>
<style>
@media (max-width: 992px) {
    .grid_iconos .item {
        grid-column-end: initial !important;
        grid-column-start: initial !important;
        grid-row-end: initial !important;
        grid-row-start: initial !important;
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .grid_iconos {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 768px) {
    .grid_iconos {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 180px);
    }
}
@media (max-width: 576px) {
    .grid_iconos {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(6, 100px);
    }
    .grid_iconos .item .icono {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
        padding: 0 2rem;
    }
    .grid_iconos .item .icono p {
        display: inline-block;
        margin-left: 1rem;
    }
}
</style>