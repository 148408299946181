<template src="@/views/home/home-template.vue"></template>
<style src="@/views/home/home.css"></style>
<script>
import Redes from '@/components/redes/Redes.vue'
import HeaderSlider from '@/components/home/HeaderSlider.vue'
import Destacadas from '@/components/noticias/Destacadas.vue'
import Owlslider from '@/components/home/Owlslider.vue'
import SetIconos from '@/components/home/SetIconos.vue'

export default {
    name: 'Home',
    components: {
        HeaderSlider,
        Destacadas,
        Owlslider,
        Redes,
        SetIconos,
    }
}
</script>