<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 mb-4">
                <div class="contenedor-redes">
                    <a class="twitter-timeline" href="https://twitter.com/TerminalSanMart?ref_src=twsrc%5Etfw">Tweets by TerminalSanMart</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 mb-4">
                <div class="contenedor-redes">
                    <div class="fb-page" data-href="https://www.facebook.com/Terminal-Santa-Marta-831588453872160" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true">
                        <blockquote cite="https://www.facebook.com/Terminal-Santa-Marta-831588453872160" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Terminal-Santa-Marta-831588453872160">Terminal de Santa Marta</a></blockquote>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 mb-4">
                <div class="elfsight-app-f4946e60-8f7e-4a05-9dc0-8b0e4dc2a585"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Redes'
}
</script>
<style lang="css">
.contenedor-redes {
    display: block;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}
.fb-page {
    width: 100%;
}
</style>